@import "~@ng-select/ng-select/themes/material.theme.css";

/* START OVERRIDES */

.bg-primary {
    background-color: #102c53 !important;
}

.bg-success {
    background-color: #0baaa2 !important;
}

.text-primary {
    color: #0baaa2 !important;
}

/* END OVERRIDES */

.ng-autocomplete {
    width: 100%;
    max-width: 600px;
    display: table;
    margin: 0 auto;
}

.paginate_button {
    cursor: pointer !important;
    color: #0baaa2;
    font-weight: bold;
}

.card-header {
    background-color: #0baaa2 !important;
}

.card-title {
    color: white !important;
}

.spl-items h6 {
	color: #102c53 !important;
}

.spl-items:hover h6 {
	color: #fff !important;
}

.spl-items i {
	color: #102c53 !important;
}

.spl-items:hover i {
	color: #fff !important;
}

.spl-items {
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #EEF1FE;
	box-shadow: 0px 7px 10px #EEF1FE;
	border-radius: 5px;	
	margin-bottom: 16px;
}
.spl-items a {	
	padding: 30px;
	display: flex;
    display: -ms-flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.spl-items:hover {
	background: #0baaa2 !important;
}

.spl-items a:hover i, .spl-items a:hover h6 {
	color: #FFF;
}
.spl-items h6 {
	color: #102c53 !important;
}

.btn-primary {
	background-color: #0baaa2 !important;
	border: 1px solid #0baaa2 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #0baaa2 !important;
	border: 1px solid #0baaa2 !important;
}


.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #0baaa2 !important;
	border: 1px solid #0baaa2 !important;
}


.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #0baaa2 !important;
	border-color: #0baaa2 !important;
	color: #fff !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #0baaa2 !important;
	border-color: #0baaa2 !important;
	color: #fff !important;
}

/* LABELS */

.label{display:inline;padding:3px 6px 4px;font-size:75%;font-weight:600;color:#fff;text-align:center;white-space:nowrap;vertical-align:baseline;-webkit-border-radius:3px;border-radius:3px}

.label.label-primary{background:#348fe2}

.label.label-secondary{background:#6c757d}

.label.label-success{background:#00acac}

.label.label-info{background:#49b6d6}

.label.label-warning{background:#f59c1a}

.label.label-danger{background:#ff5b57}

.label.label-light{background:#fff;color:#2d353c}

.label.label-dark{background:#2d353c}

.label.label-inverse{background:#2d353c}

.label.label-black{background:#000}

.label.label-white{background:#fff}

.label.label-grey{background:#b6c2c9}

.label.label-muted{background:#b6c2c9}

.label.label-silver{background:#b6c2c9}

.label.label-lime{background:#90ca4b}

.label.label-aqua{background:#49b6d6}

.label.label-blue{background:#348fe2}

.label.label-indigo{background:#8753de}

.label.label-purple{background:#727cb6}

.label.label-pink{background:#fb5597}

.label.label-red{background:#ff5b57}

.label.label-orange{background:#f59c1a}

.label.label-yellow{color:#2d353c;background:#ffd900}

.label.label-green{background:#32a932}

.label.label-teal{background:#00acac}

.label.label-cyan{background:#49b6d6}

.label.label-white{background:#fff}

.label.label-gray{background:#6c757d}

.label.label-gray-dark{background:#343a40}

.label.label-black{background:#000}

.label.label-dark{background:#2d353c}

.label.label-dark-darker{background:#1a2229}

.label.label-lime{background:#90ca4b}

.label.label-light{background:#f2f3f4}

.label.label-silver{background:#b6c2c9}

.label.label-muted{background:#627884}

.label.label-aqua{background:#49b6d6}

.autosize {
    height: auto !important;
    min-height: 34px;
    max-height: 9999px;
    overflow: hidden;
}

.dataTables_empty {
    display: none;
}